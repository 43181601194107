import React from "react";

const BlueAmpersand = () => {
  return (
    <svg
      width="42"
      height="65"
      viewBox="0 0 42 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8 16.1C4.8 20.1 6.3 23.9 9 26.9C3.5 30.8 0 37.2 0 44C0 55.5 9.39999 64.9 20.9 64.9C32.4 64.9 41.8 55.5 41.8 44C41.8 38.8 39.9 33.8 36.4 29.9C36.2 29.7 35.9 29.5 35.7 29.4C36.9 27.4 37.6 25.2 37.7 23.1C37.8 21.7 36.8 20.6 35.4 20.5C34 20.4 32.9 21.4 32.8 22.8C32.6 25.9 30.6 28.6 28.9 30.2C27.7 31.4 27.1 31.6 25 32.3C24.1 32.6 23.1 33 21.7 33.5C17.7 35 14.6 38.4 13.7 41.9C13 44.7 13.7 47.4 15.6 49.6C16.1 50.2 16.8 50.4 17.5 50.4C18.1 50.4 18.6 50.2 19.1 49.8C20.1 48.9 20.2 47.4 19.3 46.3C18.4 45.3 18.1 44.2 18.5 43C19 41 21 39 23.5 38C24.8 37.5 25.8 37.1 26.6 36.9C28.9 36.1 30.3 35.6 32.4 33.6C32.6 33.4 32.7 33.3 32.8 33.1C35.5 36 37 39.9 37 43.9C37 52.7 29.8 59.9 21 59.9C12.2 59.9 5 52.7 5 43.9C5 37.7 8.6 32.1 14.3 29.4C14.4 29.4 14.4 29.3 14.5 29.3C14.6 29.2 14.7 29.2 14.8 29.1L14.9 29C15 28.9 15.1 28.8 15.2 28.7C15.2 28.7 15.2 28.6 15.3 28.6C15.3 28.6 15.3 28.6 15.3 28.5C15.3 28.4 15.4 28.3 15.4 28.3C15.4 28.2 15.5 28.2 15.5 28.1C15.5 28 15.5 27.9 15.6 27.9C15.6 27.8 15.6 27.7 15.7 27.7C15.7 27.6 15.7 27.5 15.7 27.5C15.7 27.4 15.7 27.3 15.7 27.3C15.7 27.2 15.7 27.1 15.7 27.1C15.7 27 15.7 26.9 15.7 26.9C15.7 26.8 15.7 26.8 15.6 26.7C15.6 26.6 15.5 26.5 15.5 26.4C15.5 26.4 15.5 26.4 15.5 26.3C15.5 26.3 15.5 26.2 15.4 26.2C15.4 26.1 15.3 26 15.3 26C15.3 25.9 15.2 25.9 15.2 25.8C15.1 25.7 15.1 25.7 15 25.6C14.9 25.5 14.9 25.5 14.8 25.4L14.7 25.3C11.6 23.2 9.89999 19.9 9.89999 16.1C9.89999 9.9 14.9 4.9 21.1 4.9C25.7 4.9 29.8 7.7 31.5 12C32 13.3 33.4 13.9 34.7 13.4C36 12.9 36.6 11.5 36.1 10.2C33.4 4 27.6 0 20.9 0C12 0 4.8 7.2 4.8 16.1Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="20.9"
          y1="0"
          x2="20.9"
          y2="64.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009ADF" />
          <stop offset="1" stopColor="#033572" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BlueAmpersand;
