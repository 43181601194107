import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import MemberCard from "../components/MemberCard";
import SidePanel from "../components/SidePanel";
import Testimonials from "../components/Testimonials";
import BlueAmpersand from "../components/BlueAmpersand";

export const pageQuery = graphql`
  {
    wp {
      page(id: "/who-we-are/", idType: URI) {
        id
        title
        whoWeArePage {
          whoWeAreTestimonialPanel {
            ... on WP_Testimonial {
              ...TestimonialContent
            }
          }
        }
      }
      members(where: { orderby: { field: TITLE, order: ASC } }) {
        nodes {
          id
          featuredImage {
            node {
              ...MediaItem
            }
          }
          teamMembers {
            memberName
            memberAccreditation
            memberDescription
            memberLinkedin {
              title
              url
            }
            memberName
            memberTitle
            memberMobileNumber
            memberOfficeNumber
            memberEmailAddress
            memberAddress
          }
        }
      }
    }
  }
`;

const whoWeAre = ({ data }) => {
  const page = data.wp.page;
  const fields = page.whoWeArePage;
  const members = data.wp.members.nodes;

  return (
    <Layout title={page.title}>
      <div className="relative 2xl:container lg:flex justify-end">
        <SidePanel title={page.title} />
        <div className="flex flex-wrap items-center justify-center lg:w-[70%] xl:w-[65%] lg:justify-end lg:space-x-5 xl:space-x-14 lg:pr-32 lg:pb-52 lg:pt-36 -mt-28 lg:mt-0">
          {members.map((member, i) => {
            return (
              <div
                key={member.id}
                className="flex flex-col lg:flex-row lg:space-x-5 xl:space-x-14 items-center"
              >
                <MemberCard
                  image={member.featuredImage.node}
                  name={member.teamMembers.memberName}
                  accreditation={member.teamMembers.memberAccreditation}
                  title={member.teamMembers.memberTitle}
                  description={member.teamMembers.memberDescription}
                  linkedin={member.teamMembers.memberLinkedin}
                  mobileNumber={member.teamMembers.memberMobileNumber}
                  officeNumber={member.teamMembers.memberOfficeNumber}
                  address={member.teamMembers.memberAddress}
                  email={member.teamMembers.memberEmailAddress}
                />
                {members[i + 1] ? <BlueAmpersand /> : ""}
              </div>
            );
          })}
        </div>
      </div>
      <Testimonials testimonials={fields.whoWeAreTestimonialPanel} />
    </Layout>
  );
};

export default whoWeAre;
