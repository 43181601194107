import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import VCard from "vcard-creator";
import imageToBase64 from "image-to-base64/browser";
import { saveAs } from "file-saver";

import Image from "./Image";
import { FaLinkedin } from "react-icons/fa";

function removeTags(str) {
  return str.replace(/<[^>]*>?/gm, "");
}

async function createVCard(
  name,
  title,
  linkedin,
  image,
  mobileNumber,
  officeNumber,
  address,
  email
) {
  const img = await imageToBase64(image.sourceUrl);
  const vcard = new VCard();
  vcard.addName(removeTags(name));
  vcard.addJobtitle(title);
  vcard.addEmail(email);
  vcard.addPhoneNumber(mobileNumber, "PREF;WORK");
  vcard.addPhoneNumber(officeNumber, "WORK");
  vcard.addAddress(address);
  vcard.addPhoto(img, "JPEG");
  vcard.addURL(encodeURI(linkedin.url));
  return vcard.toString();
}

function generateDownload(data, name) {
  const blob = new Blob([data], {
    type: "text/plain;charset=utf-8",
  });
  saveAs(blob, `${name.replace(/ /g, "-")}.vcf`);
}

const MemberCard = ({
  name,
  title,
  accreditation,
  description,
  linkedin,
  image,
  mobileNumber,
  officeNumber,
  address,
  email,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  function handleClick() {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
  }

  return (
    <div className="text-darkblue max-w-[500px] lg:max-w-[264px] p-12 relative z-20 lg:p-0">
      <Image
        image={image}
        alt={image.altText}
        className={`mb-4 lg:w-[264px] object-cover`}
      />
      <div className="text-xl">
        <strong className="font-bold">{name}</strong>{" "}
        <span className="text-base">{accreditation}</span>
      </div>
      <p className="mb-8 text-xl">{title}</p>
      <div
        className="mb-4"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      <div className="flex items-center space-x-8">
        <a href={linkedin.url} className="text-3xl text-cerulean">
          <FaLinkedin />
        </a>
        <button
          className={`group flex border-2 btn overflow-hidden transition duration-200 relative rounded-2xl px-4 py-1 text-sm justify-between items-center max-w-[250px] text-cerulean border-cerulean hover:text-white"`}
          onClick={async (e) => {
            const vcard = await createVCard(
              name,
              title,
              linkedin,
              image,
              mobileNumber,
              officeNumber,
              address,
              email
            );
            generateDownload(vcard, name);
            handleClick();
          }}
        >
          <span
            className={`link-bg absolute transition duration-200 -left-full h-full w-full bg-cerulean group-hover:left-0`}
          ></span>
          <span className="z-10 transition duration-300 flex justify-between w-full group-hover:text-white">
            VCard
            <BsArrowRight className="text-xl" />
          </span>
        </button>
      </div>
      <p
        className={`transition-all duration-500 mt-4 text-cerulean ${
          isClicked ? "opacity-100" : "opacity-0"
        }`}
      >
        Downloaded
      </p>
    </div>
  );
};

export default MemberCard;
